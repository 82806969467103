<template>
  <div class="navi">
    <div class="wrapper">
      <img src="../assets/huli.png" />
      <span>Huli</span>
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <router-link :to="item.link" :class="{ active: currentPage === list[index].name }"> {{ item.name }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { id: 1, name: "Info", link: "/Info" },
        { id: 2, name: "Projects", link: "/Projects" },
        { id: 3, name: "Services", link: "#" },
        { id: 4, name: "Courses", link: "#" },
        { id: 5, name: "Blog", link: "#" },
        { id: 6, name: "Contact", link: "/Contact" },
      ],
    };
  },
  methods: {},
  props: {
    currentPage: String,
  },
};
</script>

<style lang="less">
.navi {
  top: 0;
  position: sticky;
  background-color: #1b1e21;
  height: 87px;
  z-index: 1;
  span {
    float: left;
    margin-left: 20px;
    font-family: deyi;
    font-size: 40px;
    font-weight: 400;
    line-height: 87px;
    color: #fff;
  }
  img {
    width: 57px;
    height: 57px;
    float: left;
    margin-top: 15px;
  }
  ul {
    float: right;

    li {
      float: left;
      margin-left: 27px;
      a {
        line-height: 87px;
        font-family: Anonymous-Pro, serif;
        font-size: 24px;
        color: #fff;
      }
      a:hover {
        color: #b5b5b5;
      }
      .active {
        // color: #E8B764;

        border-bottom: 2px solid #e8b764;
      }
      .active:hover {
        color: #fff;
      }
    }
  }
}
</style>