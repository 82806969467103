<template>
 <div class="under">
    <HuliNavigationVue :current-page="currentPage"></HuliNavigationVue>
    <HuliProjects></HuliProjects>
    <HuliFooter></HuliFooter>
  </div>
</template>

<script>
import HuliNavigationVue from "@/component/HuliNavigation.vue";
import HuliFooter from "@/component/HuliFooter.vue";
import HuliProjects from "@/component/Projects/HuliProjects.vue";

export default {
  components: {
    HuliNavigationVue,
    HuliProjects,
    HuliFooter,
  },
  data() {
    return {
      currentPage: "Projects",
    };
  },
}
</script>

<style>

</style>
