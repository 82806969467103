<template>
  <div class="contact wrapper wow animate__animated animate__zoomIn">
    <div style="height: 300px"></div>
    <div
      class="contactLeft wow animate__animated animate__fadeInLeft"
      data-wow-duration="1s"
    >
      <img src="../../assets/contact/Hulihead.png" />
    </div>
    <div
      class="contactRight wow animate__animated animate__fadeInRight"
      data-wow-duration="1s"
    >
      <div class="name">Huli</div>
      <div class="identy">computer graphics enthusiast</div>
      <div class="contactme">Contact Me</div>

      <div class="contactways">
        <div class="waysLeft">
          <ul>
            <li>·E-mail</li>
            <li>·Bilibili</li>
            <li>·Github</li>
            <li>·Twitter</li>
          </ul>
        </div>
        <div class="waysRight">
          <ul>
            <li><a href="#">huliawsl@gmail.com</a></li>
            <li><a href="#">UID 403409690 UID 508512371</a></li>
            <li><a href="#">@huliawsl</a></li>
            <li><a href="#">@huli41994994</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.contact {
  height: 900px;
}
.contactLeft {
  float: left;
  img {
    width: 385px;
    height: 385px;
  }
}
.contactRight {
  float: left;
  margin-left: 70px;
  .name {
    height: 72px;
    font-family: "AlibabaSans";
    font-size: 60px;
    font-weight: 900;

    line-height: 72px;
    color: white;
  }
  .identy {
    height: 36px;
    font-family: "Anonymous-Pro";
    font-size: 36px;
    font-weight: 400;

    line-height: 36px;
    color: white;
  }
  .contactme {
    margin-top: 12px;
    height: 24px;
    font-family: "Anonymous-Pro";
    font-size: 24px;
    font-weight: 400;

    line-height: 24px;
    color: white;
  }
  .contactways {
    margin-top: 21px;
    width: 647px;
    height: 204px;
    border-top: 3px solid #e7c48a;
    border-bottom: 3px solid #e7c48a;
    .waysLeft {
      float: left;
      ul {
        margin-top: 12px;
        li {
          font-family: "Anonymous-Pro";
          font-size: 24px;
          font-weight: 400;
          line-height: 44px;
          color: white;
        }
      }
    }
    .waysRight {
      float: right;
      ul {
        margin-top: 12px;
        li {
          font-family: "Anonymous-Pro";
          font-size: 24px;
          font-weight: 400;
          line-height: 44px;

          text-align: right;
          a {
            color: white;
          }
        }
      }
    }
  }
}
</style>