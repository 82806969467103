<template>
 <div class="under">
    <HuliNavigationVue :current-page="currentPage"></HuliNavigationVue>
    <HuliContact></HuliContact>
    <HuliFooter></HuliFooter>
  </div>
</template>

<script>
import HuliNavigationVue from "@/component/HuliNavigation.vue";
import HuliFooter from "@/component/HuliFooter.vue";
import HuliContact from "@/component/Contact/HuliContact.vue";
export default {
  components: {
    HuliNavigationVue,
    HuliContact,
    HuliFooter,
  },
  data() {
    return {
      currentPage: "Contact",
    };
  },
}
</script>

<style>

</style>