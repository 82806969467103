<template>
  <div class="under">
    <HuliNavigationVue :current-page="currentPage"></HuliNavigationVue>

    <div class="pic-bgc">
      <div
        class="top-pic"
        data-wow-duration="1s"
      >
        <img src="../assets/info/banner.png" />
      </div>
    </div>

    <HuliIntro></HuliIntro>

    <HuliGallery></HuliGallery>

    <HuliOngoing></HuliOngoing>

    <HuliFooter></HuliFooter>
  </div>
</template>

<script>
import HuliNavigationVue from "@/component/HuliNavigation.vue";
import HuliIntro from "@/component/Info/HuliIntro.vue";
import HuliGallery from "@/component/Info/HuliGallery.vue";
import HuliOngoing from "@/component/Info/HuliOngoing.vue";
import HuliFooter from "@/component/HuliFooter.vue";
export default {
  components: {
    HuliNavigationVue,
    HuliIntro,
    HuliGallery,
    HuliOngoing,
    HuliFooter,
  },
  data() {
    return {
      currentPage: "Info",
    };
  },
};
</script>

<style>

.top-pic {
  width: 1920px;
  height: 903px;
  margin: 0 auto;
  background-color: #06080a;
}
.pic-bgc {
  background-color: #06080a;
}
</style>
